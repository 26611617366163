@font-face {
  font-family: "Avenir";
  src: url("/public/fonts/Avenir-Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.Header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 48px;
}

.floating-text {
  position: fixed;
  top: 50vh;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  font-size: 0.8rem;
  font-family: "Avenir", sans-serif;
  letter-spacing: 4px;
  white-space: nowrap;
  opacity: 0.5;
  font-weight: bold;
  backface-visibility: hidden;
  will-change: transform;
}

.Logo {
  position: relative;
  height: 100%;
}

.FullLogo,
.PartialLogo {
  width: 125px;
  position: absolute;
  top: 20px;
  left: 80px;
  transform: translate(-50%, -50%);
  transition: opacity 0.5s;
}

.DigitalClock {
  width: 125px;
  letter-spacing: 1px;
  position: absolute;
  top: 35px;
  left: 80px;
  transform: translate(-50%, -50%);
  color: white;
  font-family: "Avenir", sans-serif;
  font-size: 12px;
}

.DigitalClock__time {
  margin: 0;
}

.FullLogo {
  opacity: 0;
}

.Header:hover .FullLogo {
  opacity: 1;
}

.Header:hover .PartialLogo {
  opacity: 0;
}

.Image {
  border: none;
  display: block;
  margin: 0;
  object-fit: cover;
  object-position: top center;
  padding: 0;
  width: 100vw;
}

.Popup {
  position: fixed;
  bottom: 0;
  font-family: "Avenir", sans-serif;
  left: 0;
  width: calc(100% - 40px); /* Subtracting 20px padding from each side */
  background-color: rgba(0, 0, 0, 0.3); /* Semi-transparent white background */
  padding: 20px;
  z-index: 1000; /* Ensure it's on top */

  transform: translateY(
    100%
  ); /* Starts off-screen (below its final position) */
  transition: transform 0.75s ease-out; /* Animation properties */
  will-change: transform; /* Optimizes the animation */
}

.Popup.slide-up {
  transform: translateY(0); /* Returns to its natural position */
}

.CloseButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none; /* Remove border */
  border-radius: 50%;
  cursor: pointer;
  width: 24px;
  height: 24px;
}

.NewsletterTitle {
  color: white;
  font-style: italic;
  margin-bottom: 5px;
  font-size: 15px;
}

#mc_embed_signup_scroll {
  display: flex;
  justify-content: center; /* centers children horizontally */
  align-items: center; /* centers children vertically */
  flex-direction: column;
  height: 100%;
}

#mc-embedded-subscribe {
  font-family: "Avenir", sans-serif;
}

.close-icon::before,
.close-icon::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 12px;
  height: 1px;
  background-color: white; /* Color of the X */
  transform-origin: center center;
}

.close-icon::before {
  transform: translate(-50%, -50%) rotate(45deg);
}

.close-icon::after {
  transform: translate(-50%, -50%) rotate(-45deg);
}

.close-icon:hover::before,
.close-icon:hover::after {
  opacity: 0.5;
}

#mce-EMAIL {
  font-family: "Avenir", sans-serif;
  background-color: rgba(255, 255, 255, 0.2); /* 50% opaque white */
  border: 0 solid rgba(0, 0, 0, 0.2); /* 50% opaque black */
  color: white;
  width: 270px;
  padding: 5px 15px;
  border-radius: 10px;
  font-size: 14px;
}

#mce-EMAIL::placeholder {
  color: white;
  opacity: 0.5;
  text-align: center;
}

/* Webkit browsers like Safari and Chrome */
#mce-EMAIL::-webkit-input-placeholder {
  color: white;
  opacity: 0.5;
  text-align: center;
}

/* Firefox */
#mce-EMAIL::-moz-placeholder {
  color: white;
  opacity: 0.5;
  text-align: center;
}

/* Firefox 19+ (this is a repetition of the previous rule and can be omitted) */
#mce-EMAIL::-moz-placeholder {
  color: white;
  opacity: 0.5;
  text-align: center;
}

/* Internet Explorer 10+ */
#mce-EMAIL:-ms-input-placeholder {
  color: white;
  opacity: 0.5;
  text-align: center;
}

#mc-embedded-subscribe {
  font-weight: light;
  margin-top: 3px;
  opacity: 0.75;
  border: none;
  font-size: 14px;
  background: transparent;
  color: white;
}

#mc-embedded-subscribe:hover {
  opacity: 0.5;
  cursor: pointer;
}

div.mce_inline_error {
  font-family: "Avenir", sans-serif !important;
  font-size: 14px !important;
  color: white !important;
  background-color: transparent !important;
  margin-bottom: 0 !important;
  text-align: center;
}

div#mce-responses {
  font-family: "Avenir", sans-serif !important;
  font-size: 14px !important;
  color: white !important;
  background-color: transparent !important;
  margin-top: 2px !important;
  margin-bottom: 0 !important;
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .Header {
    height: 72px;
  }

  .floating-text {
    font-size: 1.25rem;
  }

  .FullLogo,
  .PartialLogo {
    width: 200px;
    left: 120px;
    top: 30px;
  }

  .DigitalClock {
    width: 200px;
    left: 120px;
    top: 50px;
    font-size: 16px;
  }
}

/* Large devices (desktops, 1024px and up) */
@media (min-width: 1024px) {
  .Header {
    height: 84px;
  }

  .floating-text {
    font-size: 1.5rem;
  }
}

/* Extra-large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .Header {
    height: 96px;
  }

  .floating-text {
    font-size: 1.75rem;
  }
}

@media (min-width: 1400px) {
  .FullLogo,
  .PartialLogo {
    width: 250px;
    left: 150px;
    top: 30px;
  }

  .DigitalClock {
    width: 250px;
    left: 150px;
    top: 55px;
    font-size: 18px;
  }
}

@media (min-width: 2000px) {
  .FullLogo,
  .PartialLogo {
    width: 350px;
    left: 200px;
    top: 35px;
  }

  .DigitalClock {
    width: 350px;
    left: 200px;
    top: 65px;
    font-size: 24px;
  }
}
